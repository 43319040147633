import type {
  TCreateCampaignRequest,
  TUpdateCampaignRequest,
  TListCampaignQuery,
  TListCampaignResponse,
  TLmsCampaign
} from '@tl-lms/types/campaign'

function createJobCampaign(payload: TCreateCampaignRequest) {
  const { fetchOnce } = useAuthFetch()
  const { data: user } = useAuth()
  if (!user.value) return null
  return fetchOnce<unknown>('/api/job-campaigns/', 'POST', payload)
}

function updateJobCampaign(idSlug: string, payload: TUpdateCampaignRequest) {
  const { fetchOnce } = useAuthFetch()
  const { data: user } = useAuth()
  if (!user.value) return null
  return fetchOnce<unknown>(`/api/job-campaigns/${idSlug}/`, 'PUT', payload)
}

function listJobCampaign(
  query: TListCampaignQuery | Ref<TListCampaignQuery>,
  asyncDataOptions?: Object,
  oFetchOptions?: Object
) {
  const { fetchOnce } = useAuthFetch()
  async function fetchHandler() {
    const _query = removeFalsyValuesFromPayload(query, ['*'])
    return await fetchOnce<TListCampaignResponse>(
      '/api/job-campaigns/',
      'GET',
      undefined,
      _query,
      oFetchOptions
    )
  }

  return useAsyncData<any>(`companies-list-${JSON.stringify(query)}`, fetchHandler, {
    // only watch if query is a ref
    watch: isRef(query) || isReactive(query) ? [query] : undefined,
    ...asyncDataOptions
  })
}

function getJobCampaign(id: string) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce<TLmsCampaign>(`/api/job-campaigns/${id}/`, 'GET')
}

function listJobCampaignOnce(query: TListCampaignQuery) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce<TListCampaignResponse>(`/api/job-campaigns/`, 'GET', undefined, query)
}

function getJobCampaignOnce(id: string) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce<TLmsCampaign>(`/api/job-campaigns/${id}/`, 'GET')
}

export function publicListJobCampaignOnce(
  query: TListCampaignQuery
): Promise<TListCampaignResponse> {
  const runtimeConfig = useRuntimeConfig()
  const regionState = useRegion()
  const baseURL = process.client
    ? runtimeConfig.public.clientApiBaseUrl
    : runtimeConfig.serverApiBaseUrl
  return $fetch('/api/job-campaigns/', {
    baseURL,
    method: 'GET',
    headers: {
      'X-Line-Of-Business': runtimeConfig.public.lineOfBusiness,
      'X-Region-Of-Business': regionState.value
    },
    query
  })
}

type UseJobCampaignReturnType = {
  createJobCampaign: typeof createJobCampaign
  updateJobCampaign: typeof updateJobCampaign
  listJobCampaign: typeof listJobCampaign
  getJobCampaign: typeof getJobCampaign
  listJobCampaignOnce: typeof listJobCampaignOnce
  getJobCampaignOnce: typeof getJobCampaignOnce
}

export function useJobCampaign(): UseJobCampaignReturnType {
  return {
    createJobCampaign,
    updateJobCampaign,
    listJobCampaign,
    getJobCampaign,
    listJobCampaignOnce,
    getJobCampaignOnce
  }
}
